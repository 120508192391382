<template>
  <div class="home">
    <div class="banner">
      <el-carousel trigger="click" :interval="5000">
        <el-carousel-item>
          <img :src="require('@/assets/img/banner-2.png')" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="container">
        <div class="product">
          <div class="game_title">
            <h4>公司介绍</h4>
          </div>
          <ul>
            <li v-for="item of game" :key="item.id">
              <div class="game">
                <!-- <div class="game_icon">
                  <img :src="item.icon" />
                </div> -->
                <div class="game_text">
                  <p
                    class="game_introduce"
                    v-for="(item_2, index) in item.introduce"
                    :key="index"
                  >
                    {{ item_2 }}
                  </p>
                </div>
              </div>
              <div class="game_img">
                <img
                  v-for="(item, index) of item.gameImg"
                  :key="index"
                  :src="item"
                />
              </div>
            </li>
          </ul>
        </div>
        <!-- <div></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      game: [
        {
          id: 1,
          introduce: [
            '许可项目：互联网信息服务；网络文化经营；第一类增值电信业务；第二类增值电信业务；',
            '一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；软件开发；软件销售；计算机系统服务；互联网设备销售；计算机软硬件及辅助设备零售；广告设计、代理；广告发布（非广播电台、电视台、报刊出版单位）；广告制作；平面设计；互联网数据服务；大数据服务；数据处理和存储支持服务；数据处理服务；信息系统运行维护服务；网络技术服务；动漫游戏开发；数字文化创意软件开发；专业设计服务；办公服务；互联网销售（除销售需要许可的商品）；销售代理；社会经济咨询服务（除许可业务外，可自主依法经营法律法规非禁止或限制的项目）（一般经营项目自主经营，许可经营项目凭相关许可证或者批准文件经营）（依法须经批准的项目，经相关部门批准后方可开展经营活动。）'
          ],
          gameImg: [require('@/assets/img/game_1.png')]
        },
        {
          id: 2,
          introduce: [
            '公司以“专注网站，用心服务”为核心价值，一切以用户需求为中心，希望通过专业水平和不懈努力，为企业产品提供服务和指导。'
          ],
          gameImg: [require('@/assets/img/game_2.png')]
        },
        {
          id: 3,
          introduce: [
            '公司的不断发展，信任安全是我们的承诺。保证合作安全、产品安全、结果有保障！'
          ],
          gameImg: [require('@/assets/img/game_3.png')]
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.banner {
  .el-carousel__item {
    ::v-deep img {
      width: 100%;
      height: 647px;
      // background-size: 100% 100%;
      background-position: center 100%;
      object-fit: cover;
    }
  }
  ::v-deep .el-carousel__container {
    height: 647px;
  }
}
.product {
  .game_title {
    padding-top: 50px;

    text-align: center;
    h4 {
      font-size: 42px;
      opacity: 1;
    }
  }
  li {
    padding: 0px 0 100px 0;
    border-bottom: 4px solid @blColor;
    &:last-child {
      border-bottom: 0px solid @blColor;
    }
    .game {
      .game_text {
        // width: 860px;
        padding-top: 40px;
        .game_introduce {
          margin-top: 20px;
          font-size: 24px;
          opacity: 1;
          font-weight: normal;
        }
      }
    }
    .game_img {
      margin-top: 50px;
      text-align: center;
      // display: flex;
      // justify-content: space-between;
      img {
        object-fit: contain;
        width: 1000px;
        // height: 686px;
      }
    }
  }
}
</style>
